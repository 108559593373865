import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import LangSwitcher from './LangSwitcher'
import MobileLangSwitcher from './MobileLangSwitcher'
import LogoImg from './logo_white.svg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useTranslation } from 'react-i18next'

const MenuWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #141b3a 0%, rgba(0, 212, 255, 0) 100%);
  padding: 10px 50px;
  @media (max-width: 600px) {
    display: none;
  }
`

const PagesWrapper = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  div {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2em;
    text-align: left;
    text-transform: uppercase;
    color: white;
    gap: 60px;
  }
`

const LogoWrapper = styled.div`
  cursor: pointer;
  color: white;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 130px;
  }
`

const MobileMenuWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999999;
  display: flex;
  justify-content: center;
  padding: 30px 50px;
  background: linear-gradient(180deg, #141b3a 0%, rgba(0, 212, 255, 0) 100%);
  @media (min-width: 600px) {
    display: none;
  }
`

const MobileLogo = styled.div`
  width: 120px;
`

const StyledBurger = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }
  div {
    width: 2rem;
    height: 3px;
    background: ${({ open }) => (open ? '#0D0C1D' : '#EFFFFA')};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #effffa;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  @media (max-width: 576px) {
    width: 100%;
  }
  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`
const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
  color: white;
`

const MenuItem = styled.p`
  font-size: 14px;
  letter-spacing: 0.2em;
  font-family: 'TT Norms Pro Medium';
  color: white;
  margin: 0;
`

const MobileMenuItem = styled.p``

const MobileLangSwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
`

const Menu = ({ mode, setMode, path }) => {
  const { t } = useTranslation('')
  const [open, setOpen] = useState(false)
  // burger menu from here https://codepen.io/maximakymenko/pen/aboWJpX

  const handleLinkClick = (event) => {
    // if the current page is the homepage trigger the mode change
    event.preventDefault()
    navigate(t('urls').index)
  }

  const talentsClick = (event) => {
    setMode('grid')
  }

  useEffect(() => {
    console.log(window.location.hash)
    // console.log('supaMode:', supaMode)
    if (path == '/talente/' || path == '/talents/' || path == '/creator/' || path == '/creators/') {
      console.log('triggered gridmode')
      setMode('grid')
      navigate(t('urls').talents)
    } else if (
      window.location.hash == '#talente' ||
      window.location.hash == '#talents' ||
      window.location.hash == '#creator' ||
      window.location.hash == '#creators'
    ) {
      console.log('triggered gridmode')
      setMode('grid')
      navigate(t('urls').talents)
    }
  }, [mode])

  return (
    <>
      <MenuWrapper>
        <LangSwitcher />
        <LogoWrapper onClick={handleLinkClick}>
          <img alt="insight-company-logo" src={LogoImg} />
        </LogoWrapper>
        <PagesWrapper>
          <div>
            <StyledAnchorLink to={t('urls').talents} onAnchorLinkClick={talentsClick}>
              <MenuItem>{t('menuItems').talents}</MenuItem>
            </StyledAnchorLink>
            <StyledAnchorLink to={t('urls').customers}>
              <MenuItem>{t('menuItems').clients}</MenuItem>
            </StyledAnchorLink>
            <StyledAnchorLink to={t('urls').process}>
              <MenuItem>{t('menuItems').process}</MenuItem>
            </StyledAnchorLink>
          </div>
        </PagesWrapper>
      </MenuWrapper>
      <MobileMenuWrapper>
        <MobileLogo onClick={handleLinkClick}>
          <img alt="insight-company-logo" src={LogoImg} />
        </MobileLogo>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <StyledMenu open={open} onClick={() => setOpen(false)}>
          <StyledAnchorLink to={t('urls').talents}>
            <MobileMenuItem>{t('menuItems').talents}</MobileMenuItem>
          </StyledAnchorLink>
          <StyledAnchorLink to={t('urls').customers}>
            <MobileMenuItem>{t('menuItems').clients}</MobileMenuItem>
          </StyledAnchorLink>
          <StyledAnchorLink to={t('urls').process}>
            <MobileMenuItem>{t('menuItems').process}</MobileMenuItem>
          </StyledAnchorLink>
          <MobileLangSwitcherWrapper>
            <MobileLangSwitcher />
          </MobileLangSwitcherWrapper>
        </StyledMenu>
      </MobileMenuWrapper>
    </>
  )
}

export default Menu
