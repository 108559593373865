import React from 'react'
import { useState, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import Menu from '../components/Menu/Menu'
import Seo from './Seo/Seo'
import CursorSrc from '../images/cursor-v2.png'
import { Script } from 'gatsby'

const GlobalStyle = createGlobalStyle`
  * {  
    box-sizing: border-box;
    font-family:'TT Norms Pro';
    font-weight:400;
    /* cursor: url(${CursorSrc}),auto; */
  }
  h1 , h2, h3, h4, h5 {
    font-family:'TT Norms Pro Bold';
  }
`

const Layout = ({ path, children }) => {
  const [mode, setMode] = useState('slider')
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { mode, setMode }))

  return (
    <>
      <Seo />
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-XS1Z076G76" />
      <Script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-XS1Z076G76');`}
      </Script>
      <GlobalStyle />
      <Menu mode={mode} setMode={setMode} path={path} />
      {childrenWithProps}
    </>
  )
}

export default Layout
